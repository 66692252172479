import {
  ExperiencesAvailabilityAutocompleteRequest,
  ExperiencesAvailabilityAutocompleteResponse,
  ExperiencesDestination,
  IIdExperiences,
  RatingRefinement,
  TripadvisorRatingRefinement,
} from "redmond";
import { put, select } from "redux-saga/effects";
import { IStoreState } from "../../../reducers/types";
import { actions } from "../actions";
import Logger from "../../../utils/logger";
import { getLocation } from "../../search/reducer";
import {
  getFilteredDurationTimes,
  getFilteredStartTimes,
  getFilteredTags,
  getFilteredTripAdvisorRating,
  getFilterPrice,
  getSortOption,
  IExperiencesAvailabilityFilterState,
} from "../reducer";
import { fetchExperiencesAvailabilityAutocomplete } from "../../../api/v0/availability/fetchExperiencesAvailabilityAutocomplete";
import { setExperiencesAvailabilityAutocomplete } from "../actions/actions";

export function* fetchExperiencesAvailabilityAutocompleteSaga(
  action: actions.IFetchExperiencesAvailabilityAutocomplete
) {
  try {
    let requestBody: ExperiencesAvailabilityAutocompleteRequest;
    const state: IStoreState = yield select();

    const destinationId = (
      (getLocation(state)?.id as IIdExperiences)
        .experiencesSelection as ExperiencesDestination
    ).id;

    // TODO: Break these to helper functions
    const refinements: IExperiencesAvailabilityFilterState = {
      tripAdvisorRating: getFilteredTripAdvisorRating(state),
      duration: getFilteredDurationTimes(state),
      startTime: getFilteredStartTimes(state),
      price: getFilterPrice(state),
      tags: getFilteredTags(state),
      sort: getSortOption(state),
    };

    let ratingRefinement: RatingRefinement = { minTripadvisorRating: 0 };
    switch (refinements.tripAdvisorRating) {
      case TripadvisorRatingRefinement.Good:
        ratingRefinement.minTripadvisorRating = 3.5;
        break;
      case TripadvisorRatingRefinement.VeryGood:
        ratingRefinement.minTripadvisorRating = 4;
        break;
      case TripadvisorRatingRefinement.Great:
        ratingRefinement.minTripadvisorRating = 4.5;
        break;
      default:
        ratingRefinement.minTripadvisorRating = 3;
        break;
    }

    requestBody = {
      destination: destinationId,
      query: action.query,
      availabilityRefinement: {
        sort: refinements.sort,
        tags: refinements.tags ? refinements.tags : [],
        duration: refinements.duration ? refinements.duration : [],
        startTime: refinements.startTime ? refinements.startTime : [],
        price: refinements.price,
        rating: ratingRefinement,
      },
    };

    let availabilityAutocompleteResponse: ExperiencesAvailabilityAutocompleteResponse =
      yield fetchExperiencesAvailabilityAutocomplete(requestBody);

    yield put(
      setExperiencesAvailabilityAutocomplete(availabilityAutocompleteResponse)
    );
  } catch (e) {
    Logger.debug(e);
  }
}
